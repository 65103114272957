import { inject, Injectable } from '@angular/core';

import { EntityValidationErrors } from '../models/app-error';
import { ValidationErrorDto } from '../dtos/validation-error.dto';
import { TrainingCreateUpdateDto, TrainingDto } from '../dtos/training.dto';
import { Training, TrainingCreateUpdate } from '../models/training';

import { extractErrorMessageByField } from './extract-error-message';
import { MapperFromDto, MapperToDto, ValidationErrorMapper } from './mappers';
import { DateMapper } from './date.mapper';

/** Mapper for training. */
@Injectable({ providedIn: 'root' })
export class TrainingMapper
implements
	MapperFromDto<TrainingDto, Training>,
	MapperToDto<TrainingCreateUpdateDto, TrainingCreateUpdate>,
	ValidationErrorMapper<TrainingDto, Training> {
	private readonly dateMapper = inject(DateMapper);

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<TrainingDto>,
	): EntityValidationErrors<Training> {
		return {
			program: extractErrorMessageByField(errorDto, 'program'),
			school: extractErrorMessageByField(errorDto, 'school'),
			hospitalSystem: extractErrorMessageByField(errorDto, 'hospital_system'),
			specialty: extractErrorMessageByField(errorDto, 'specialty'),
			date: extractErrorMessageByField(errorDto, 'date'),
			nonFieldErrors: extractErrorMessageByField(errorDto, 'non_field_errors'),
		};
	}

	/** @inheritdoc */
	public fromDto(dto: TrainingDto): Training {
		return {
			id: dto.id,
			program: dto.program,
			school: dto.school,
			hospitalSystem: dto.hospital_system,
			specialty: dto.specialty,
			date: this.dateMapper.fromDto(dto.date),
		};
	}

	/** @inheritdoc */
	public toDto(model: TrainingCreateUpdate): TrainingCreateUpdateDto {
		return {
			program: model.program,
			school: model.school,
			hospital_system: model.hospitalSystem,
			specialty: model.specialty,
			date: this.dateMapper.toDateOnlyDto(model.date),
		};
	}
}
