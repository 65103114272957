import { Injectable } from '@angular/core';

import { Mapper } from './mappers';

/** Phone number mapper. */
@Injectable({
	providedIn: 'root',
})
export class PhoneNumberMapper implements Mapper<string, string> {

	/** @inheritdoc */
	public toDto(model: string): string {
		if (model === '') {
			return '';
		}
		return `+1${model}`;
	}

	/** @inheritdoc */
	public fromDto(dto: string): string {
		return dto.slice(2);
	}
}
