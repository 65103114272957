import { Injectable } from '@angular/core';

import { EducationFileCreateUpdateDto, EducationFileDto } from '../dtos/education-file.dto';
import { EducationFile, EducationFileCreateUpdate } from '../models/education-file';

import { MapperFromDto } from './mappers';

/** Mapper for education file. */
@Injectable({ providedIn: 'root' })
export class EducationFileMapper implements MapperFromDto<EducationFileDto, EducationFile> {

	/** @inheritdoc */
	public fromDto(dto: EducationFileDto): EducationFile {
		return {
			id: dto.id,
			url: dto.file,
			name: dto.name,
		};
	}

	/**
	 * Maps education file create model to DTO.
	 * @param model Education file create.
	 */
	public toCreateDto(model: EducationFileCreateUpdate): EducationFileCreateUpdateDto {
		return {
			name: model.name,
			file: model.url,
		};
	}
}
