import { Injectable, inject } from '@angular/core';

import { MapperFromDto, MapperToDto, ValidationErrorMapper } from '../mappers';
import { CredentialDto, CredentialCreateDto } from '../../dtos/credentials/credential.dto';
import { Credential, CredentialCreate } from '../../models/credentials/credential';
import { DateMapper } from '../date.mapper';
import { ValidationErrorDto } from '../../dtos/validation-error.dto';
import { EntityValidationErrors } from '../../models/app-error';
import { extractErrorMessageByField } from '../extract-error-message';

/** Credential mapper. */
@Injectable({ providedIn: 'root' })
export class CredentialMapper implements
MapperFromDto<CredentialDto, Credential>,
MapperToDto<CredentialCreate, CredentialCreateDto>,
ValidationErrorMapper<CredentialCreateDto, CredentialCreate> {
	private readonly dateMapper = inject(DateMapper);

	/** @inheritdoc */
	public validationErrorFromDto(errorDto: ValidationErrorDto<CredentialCreateDto>): EntityValidationErrors<CredentialCreate> {
		return {
			type: extractErrorMessageByField(errorDto, 'type'),
			issuedOnDate: extractErrorMessageByField(errorDto, 'issued_on'),
			expiresOnDate: extractErrorMessageByField(errorDto, 'expires_on'),
			status: extractErrorMessageByField(errorDto, 'status'),
			certificationNumber: extractErrorMessageByField(errorDto, 'certification_number'),
			licenseNumber: extractErrorMessageByField(errorDto, 'license_number'),
			registrationNumber: extractErrorMessageByField(errorDto, 'registration_number'),
			certificationBoard: extractErrorMessageByField(errorDto, 'certification_board'),
			state: extractErrorMessageByField(errorDto, 'state'),
			file: extractErrorMessageByField(errorDto, 'file'),
			insuranceCarrier: extractErrorMessageByField(errorDto, 'insurance_carrier'),
			coverage: extractErrorMessageByField(errorDto, 'coverage'),
			effectiveDate: extractErrorMessageByField(errorDto, 'effective_date'),
			nonFieldErrors: extractErrorMessageByField(errorDto, 'non_field_errors'),
		};
	}

	/** @inheritdoc */
	public toDto(model: CredentialCreate): CredentialCreateDto {
		return {
			type: model.type,
			issued_on: model.issuedOnDate ? this.dateMapper.toDateOnlyDto(model.issuedOnDate) : undefined,
			expires_on: model.expiresOnDate ? this.dateMapper.toDateOnlyDto(model.expiresOnDate) : undefined,
			status: model.status,
			certification_number: model.certificationNumber,
			license_number: model.licenseNumber,
			registration_number: model.registrationNumber,
			policy_number: model.policyNumber,
			certification_board: model.certificationBoard,
			state: model.state,
			file: model.file,
			insurance_carrier: model.insuranceCarrier,
			coverage: model.coverage,
			effective_date: model.effectiveDate ? this.dateMapper.toDateOnlyDto(model.effectiveDate) : undefined,
		};
	}

	/** @inheritdoc */
	public fromDto(dto: CredentialDto): Credential {
		return {
			id: dto.id,
			type: dto.type,
			issuedOnDate: dto.issued_on ? this.dateMapper.fromDto(dto.issued_on) : null,
			expiresOnDate: dto.expires_on ? this.dateMapper.fromDto(dto.expires_on) : null,
			status: dto.status,
			certificationNumber: dto.certification_number,
			licenseNumber: dto.license_number,
			registrationNumber: dto.registration_number,
			certificationBoard: dto.certification_board,
			policyNumber: dto.policy_number,
			state: dto.state,
			file: dto.file,
			insuranceCarrier: dto.insurance_carrier,
			coverage: dto.coverage,
			effectiveDate: dto.effective_date ? this.dateMapper.fromDto(dto.effective_date) : null,
		};
	}
}
