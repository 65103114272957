import { Injectable, inject } from '@angular/core';

import { UserDto, UserUpdateDataDto } from '../dtos/user.dto';
import { ValidationErrorDto } from '../dtos/validation-error.dto';
import { EntityValidationErrors } from '../models/app-error';
import { User, UserUpdateData } from '../models/user';
import { mapIfNotUndefined } from '../utils/map-if-not-undefined';

import { DateMapper } from './date.mapper';
import { extractErrorMessageByField } from './extract-error-message';
import { MapperFromDto, ValidationErrorMapper } from './mappers';
import { EducationMapper } from './education.mapper';
import { TrainingMapper } from './training.mapper';
import { CredentialMapper } from './credentials/credential.mapper';
import { PhoneNumberMapper } from './phone-number.mapper';
import { TwoFactorAuthMethodMapper } from './two-factor-method.mapper';

/** User profile mapper. */
@Injectable({ providedIn: 'root' })
export class UserMapper implements
MapperFromDto<UserDto, User>,
ValidationErrorMapper<UserUpdateDataDto, UserUpdateData> {

	private readonly credentialMapper = inject(CredentialMapper);

	private readonly dateMapper = inject(DateMapper);

	private readonly educationMapper = inject(EducationMapper);

	private readonly trainingMapper = inject(TrainingMapper);

	private readonly phoneNumberMapper = inject(PhoneNumberMapper);

	private readonly twoFactorAuthMapper = inject(TwoFactorAuthMethodMapper);

	/** @inheritdoc */
	public fromDto(dto: UserDto): User {
		return {
			id: dto.id,
			avatarUrl: dto.avatar ?? '',
			backgroundUrl: dto.background_image ?? '',
			firstName: dto.first_name,
			lastName: dto.last_name,
			preferredName: dto.preferred_name != null && dto.preferred_name !== '' ? dto.preferred_name : null,
			initials: dto.initials != null && dto.initials !== '' ? dto.initials : null,
			credentials: dto.credentials,
			username: dto.username,
			entity: dto.entity ?? '',
			pronoun: dto.pronoun ?? '',
			clinician: dto.clinician_type,
			graduationDate: dto.graduation_date ? this.dateMapper.fromDto(dto.graduation_date) : null,
			email: dto.email,
			secondaryEmail: dto.secondary_email ?? '',
			phoneNumber: this.phoneNumberMapper.fromDto(dto.phone_number),
			fax: dto.fax_number != null ? this.phoneNumberMapper.fromDto(dto.fax_number) : '',
			description: dto.description ?? '',
			specialty: dto.specialty,
			specialtyArea: dto.specialty_area ?? '',
			createdDate: this.dateMapper.fromDto(dto.created),
			address: {
				city: dto.address ?? '',
				state: dto.address_state ?? '',
			},
			practiceAddress: {
				state: dto.primary_region_practice_state,
				zipCode: dto.primary_region_practice_zip,
			},
			totalContacts: dto.total_contacts,
			hasContact: dto.has_contact,
			educations: dto.educations.map(educationDto => this.educationMapper.fromDto(educationDto)),
			trainings: dto.trainings.map(trainingDto => this.trainingMapper.fromDto(trainingDto)),
			documents: dto.documents.map(document => this.credentialMapper.fromDto(document)),
			npiNumber: dto.npi_number,
			twoFactorAuth: dto.two_factor_type == null || dto.two_factor_type === '' ?
				null :
				this.twoFactorAuthMapper.fromDto(dto.two_factor_type),
			isStudent: dto.role === 'student',
			allowNotifications: dto.allow_notifications ?? false,
			rating: dto.average_rating != null && dto.number_of_rating_consultations != null ? {
				averateRating: dto.average_rating,
				ratedConsultationsAmount: dto.number_of_rating_consultations,
			} : null,
			isAvailableForPages: dto.available_for_page ?? false,
			baaFile: dto.baa_file,
		};
	}

	/**
	 * Map data to update user data DTO.
	 * @param model User update data.
	 */
	public toUpdateDataDto(model: UserUpdateData): UserUpdateDataDto {
		return {
			avatar: model.avatarUrl,
			background_image: model.backgroundUrl === '' ? null : model.backgroundUrl,
			first_name: model.firstName,
			last_name: model.lastName,
			preferred_name: model.preferredName ?? undefined,
			initials: model.initials ?? undefined,
			credentials: model.credentials,
			username: model.username,
			entity: model.entity,
			pronoun: model.pronoun,
			clinician_type: model.clinician,
			graduation_date: mapIfNotUndefined(
				model.graduationDate,
				graduationDate => graduationDate === null ? null : this.dateMapper.toDateOnlyDto(graduationDate),
			),
			address: model.address?.city,
			address_state: model.address?.state,
			primary_region_practice_state: model.practiceAddress?.state,
			primary_region_practice_zip: model.practiceAddress?.zipCode,
			fax_number: model.fax != null ? this.phoneNumberMapper.toDto(model.fax) : undefined,
			description: model.description,
			specialty: model.specialty ? [...model.specialty] : undefined,
			specialty_area: model.specialtyArea,
			npi_number: model.npiNumber,
			role: mapIfNotUndefined(model.isStudent, isStudent => isStudent ? 'student' : 'clinician'),
			allow_notifications: model.allowNotifications,
			available_for_page: model.isAvailableForPages,
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(errorDto: ValidationErrorDto<UserUpdateDataDto>): EntityValidationErrors<UserUpdateData> {
		return {
			avatarUrl: extractErrorMessageByField(errorDto, 'avatar'),
			backgroundUrl: extractErrorMessageByField(errorDto, 'background_image'),
			firstName: extractErrorMessageByField(errorDto, 'first_name'),
			lastName: extractErrorMessageByField(errorDto, 'last_name'),
			preferredName: extractErrorMessageByField(errorDto, 'preferred_name'),
			initials: extractErrorMessageByField(errorDto, 'initials'),
			username: extractErrorMessageByField(errorDto, 'username'),
			entity: extractErrorMessageByField(errorDto, 'entity'),
			pronoun: extractErrorMessageByField(errorDto, 'pronoun'),
			clinician: extractErrorMessageByField(errorDto, 'clinician_type'),
			graduationDate: extractErrorMessageByField(errorDto, 'graduation_date'),
			address: {
				city: extractErrorMessageByField(errorDto, 'address'),
				state: extractErrorMessageByField(errorDto, 'address_state'),
			},
			practiceAddress: {
				state: extractErrorMessageByField(errorDto, 'primary_region_practice_state'),
				zipCode: extractErrorMessageByField(errorDto, 'primary_region_practice_zip'),
			},
			fax: extractErrorMessageByField(errorDto, 'fax_number'),
			description: extractErrorMessageByField(errorDto, 'description'),
			specialty: extractErrorMessageByField(errorDto, 'specialty'),
			specialtyArea: extractErrorMessageByField(errorDto, 'specialty_area'),
			npiNumber: extractErrorMessageByField(errorDto, 'npi_number'),
			nonFieldErrors: extractErrorMessageByField(errorDto, 'non_field_errors'),
		};
	}
}
