import { Injectable } from '@angular/core';

import { TwoFactorMethodDto, twoFactorMethodSchema } from '../dtos/two-factor/two-factor-method.dto';

import { TwoFactorMethod } from '../models/two-factor/two-factor-method';
import { reverseRecord } from '../utils/reverse-record';

import { Mapper } from './mappers';

const FROM_DTO_DICT: Readonly<Record<NonNullable<TwoFactorMethodDto>, TwoFactorMethod>> = {
	[twoFactorMethodSchema.Enum.totp]: 'app',
	[twoFactorMethodSchema.Enum.sms]: 'sms',
};

const TO_DTO_DICT = reverseRecord(FROM_DTO_DICT);

/** Two-factor auth method mapper. */
@Injectable({ providedIn: 'root' })
export class TwoFactorAuthMethodMapper implements Mapper<TwoFactorMethodDto, TwoFactorMethod> {

	/** @inheritdoc */
	public fromDto(dto: TwoFactorMethodDto): TwoFactorMethod {
		return FROM_DTO_DICT[dto];
	}

	/** @inheritdoc */
	public toDto(dto: TwoFactorMethod): TwoFactorMethodDto {
		return TO_DTO_DICT[dto];
	}
}
