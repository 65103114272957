/* eslint-disable jsdoc/require-description-complete-sentence */
import { ApiError, ValidationErrorDto } from '../dtos/validation-error.dto';
import { MaxErrorDepth, ObjectPaths } from '../utils/types/object-paths';

/** None field error attribute. */
export type NoneFieldError = 'non_field_errors';

/** Parsed error attribute. */
type ParsedErrorAttribute = {

	/** Last attribute segment. */
	readonly arrayIndex: string | undefined;
};

/**
 * Parses error attribute.
 * @param error Error.
 * @param field Error attribute.
 */
function parseErrorAttr<T>(error: ApiError<T>, field: ObjectPaths<T, MaxErrorDepth> | NoneFieldError): ParsedErrorAttribute {
	const attrSegments = String(error.attr)
		.replace(`${String(field)}`, '')
		.split('.');
	return {
		arrayIndex: attrSegments.at(-1),
	};
}

/**
 * If the error key ends with a number, the error is on the field array.
 *
 * ```ts
 * 	recipients.1 -> for array
 * 	recipients.1.name -> for array
 * 	recipients.name -> for single field
 * ```
 * @param errors Errors.
 * @param field Error field.
 */
function isErrorForArray<T>(errors: readonly ApiError<T>[], field: ObjectPaths<T, MaxErrorDepth> | NoneFieldError): boolean {
	return errors.every(error => {
		const { arrayIndex } = parseErrorAttr(error, field);
		return arrayIndex !== '' && !isNaN(Number(arrayIndex));
	});
}

/**
 * Extracts only one error format.
 * If the error came in the format `error.0` `error.1` ... Etc.
 * Then an array will be returned, where the array index will match the last digit in the error field.
 *
 * Example
 * Input:
 *
 * ```ts
 *	[
 *		{
 *				"code": "required",
 *				"detail": "This field is required.",
 *				"attr": "recipients.1"
 *		},
 *		{
 *				"code": "invalid",
 *				"detail": "Enter a valid email address.",
 *				"attr": "recipients.2"
 *		}
 *	]
 * ```
 *
 * Output:
 * ```ts
 * 	[
 * 		[1]: "This field is required.",
 * 		[2]: "Enter a valid email address.",
 * 	]
 * ```
 * @param errorsByField Errors array.
 * @param field Error field.
 */
function extractErrorMessageFromArray<T>(
	errorsByField: readonly ApiError<T>[],
	field: ObjectPaths<T, MaxErrorDepth> | NoneFieldError,
): string[] {
	const fieldErrors: string[] = [];
	errorsByField.forEach(error => {
		const { arrayIndex } = parseErrorAttr(error, field);

		if (!isNaN(Number(arrayIndex))) {
			fieldErrors[Number(arrayIndex)] = error.detail;
		}
	});

	return fieldErrors;
}

/**
 * Extracts error by field.
 *
 * If the beginning of the attribute is read with the field name, the error will be extracted.
 *
 * Example
 * Input:
 *
 * ```ts
 * field = "recipients"
 * {
 *	"code": "required",
 *	"detail": "This field is required.",
 *	"attr": "recipients.0.name"
 * }
 * ```
 *
 * Output:
 * ```ts
 * 	"This field is required."
 * ```
 * @param field Form field.
 * @param errorData Error data.
 */
export function extractErrorMessageByField<T>(
	errorData: ValidationErrorDto<T>,
	field: ObjectPaths<T, MaxErrorDepth> | NoneFieldError,
): string | string[] | undefined {
	const { errors } = errorData;

	// Use === instead of startsWith to prevent such cases: address and address_zip
	const errorsByField = errors.filter(error => String(error.attr) === String(field));
	if (errorsByField.length === 0) {
		return undefined;
	}

	if (!isErrorForArray(errorsByField, field)) {
		return errorsByField[0].detail;
	}

	return extractErrorMessageFromArray(errorsByField, field);
}
