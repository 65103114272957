import { Injectable, inject } from '@angular/core';

import { EntityValidationErrors } from '../models/app-error';
import { ValidationErrorDto } from '../dtos/validation-error.dto';

import { EducationCreateUpdateDto, EducationDto } from '../dtos/education.dto';

import { Education, EducationCreateUpdate } from '../models/education';

import { extractErrorMessageByField } from './extract-error-message';
import { MapperFromDto, MapperToDto, ValidationErrorMapper } from './mappers';
import { DateMapper } from './date.mapper';
import { EducationFileMapper } from './education-file.mapper';

/** Mapper for education. */
@Injectable({ providedIn: 'root' })
export class EducationMapper
implements
	MapperFromDto<EducationDto, Education>,
	MapperToDto<EducationCreateUpdateDto, EducationCreateUpdate>,
	ValidationErrorMapper<EducationDto, Education> {

	private readonly dateMapper = inject(DateMapper);

	private readonly educationFileMapper = inject(EducationFileMapper);

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<EducationDto>,
	): EntityValidationErrors<Education> {
		return {
			program: extractErrorMessageByField(errorDto, 'program'),
			school: extractErrorMessageByField(errorDto, 'school'),
			city: extractErrorMessageByField(errorDto, 'city'),
			state: extractErrorMessageByField(errorDto, 'state'),
			dateOfCompletion: extractErrorMessageByField(errorDto, 'date_of_completion'),
			major: extractErrorMessageByField(errorDto, 'major'),
			degreeCertificateEarned: extractErrorMessageByField(errorDto, 'degree_certificate_earned'),
			files: extractErrorMessageByField(errorDto, 'files'),
			nonFieldErrors: extractErrorMessageByField(errorDto, 'non_field_errors'),
		};
	}

	/** @inheritdoc */
	public fromDto(dto: EducationDto): Education {
		return {
			id: dto.id,
			program: dto.program,
			school: dto.school,
			city: dto.city,
			state: dto.state,
			dateOfCompletion: this.dateMapper.fromDto(dto.date_of_completion),
			major: dto.major,
			degreeCertificateEarned: dto.degree_certificate_earned,
			files: dto.files.map(file => this.educationFileMapper.fromDto(file)),
		};
	}

	/** @inheritdoc */
	public toDto(model: EducationCreateUpdate): EducationCreateUpdateDto {
		return {
			program: model.program,
			school: model.school,
			city: model.city,
			state: model.state,
			date_of_completion: this.dateMapper.toDateOnlyDto(model.dateOfCompletion),
			major: model.major,
			degree_certificate_earned: model.degreeCertificateEarned ??	'',
			files: model.files.map(file => this.educationFileMapper.toCreateDto(file)),
		};
	}
}
