import { HttpErrorResponse } from '@angular/common/http';

import { NoneFieldError } from '../mappers/extract-error-message';
import { MaxErrorDepth, ObjectPaths } from '../utils/types/object-paths';

type ApiErrorType =
	| 'validation_error'
	| 'parse_error'
	| 'authentication_failed'
	| 'not_authenticated'
	| 'permission_denied'
	| 'not_found'
	| 'method_not_allowed'
	| 'not_acceptable'
	| 'unsupported_media_type'
	| 'throttled'
	| 'server_error';

type ApiErrorCode = 'blank' | 'invalid';

/** Error returned by API. */
export type ApiError<T> = {

	/** Error code. */
	readonly code: ApiErrorCode;

	/** Error description.  */
	readonly detail: string;

	/** Key of error.*/
	readonly attr: ObjectPaths<T, MaxErrorDepth> | NoneFieldError;
};

/** Validation error DTO. */
export type ValidationErrorDto<T> = {

	/** Error type. */
	readonly type: ApiErrorType;

	/** Errors. */
	readonly errors: readonly ApiError<T>[];
};

/**
 * Is error a ValidationErrorDto.
 * @param error Some error.
 */
export function isValidationErrorDto<T>(error: HttpErrorResponse['error']): error is ValidationErrorDto<T> {
	return (error as ValidationErrorDto<T>).type != null;
}
